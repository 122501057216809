$iconBackward: '\e901';
$iconForward: '\e902';
$iconPause: '\e903';
$iconPlay: '\e904';
$iconProfile: '\e905';
$iconArrowRight: '\e906';
$iconArrowLeft: '\e907';
$iconArrowDown: '\e908';
$iconExit: '\e909';
$iconHeart: '\e90a';
$iconCheck: '\e90b';
$iconAlert: '\e90c';
$iconCart: '\e90d';
$iconLocation: '\e90e';
$iconGlobe: '\e90f';
$iconMenu: '\e910';
$iconSearch: '\e911';
$iconSupport: '\e913';
$iconAlertAlt: '\e914';
$iconCaretDown: '\e916';
$iconCaretUp: '\e918';
$iconCaretRight: '\e91a';
$iconCaretLeft: '\e92f';
$iconExternalLink: '\e91c';
$iconPlus: '\e91e';
$iconShare: '\e900';
$iconVolumeMute: '\e91f';
$iconClosedCaption: '\e920';
$iconApple: '\e921';
$iconFacebook: '\e922';
$iconLinkedin: '\e924';
$iconInstagram: '\e925';
$iconYoutube: '\e926';
$iconGoogle: '\e927';
$iconWeChat: '\e928';
$iconVK: '\e929';
$iconTencentWeibo: '\e92a';
$iconSinaWeibo: '\e92b';
$iconTumblr: '\e92c';
$iconLine: '\e92d';
$iconRenren: '\e92e';
$iconFlickr: '\e930';
$iconAccessibility: '\e931';
$iconPrizeEnter: '\e932';
$iconVolumeUnmute: '\e933';
$iconXSocial: '\e934';
$iconChatOnline: '\e935';
$iconChatOffline: '\e936';
$iconTikTok: '\e938';
$iconAppleMusic: '\e939';
$iconSpotify: '\e93a';

// VideoJS font icons
$videoJsIcons: (
    play: '\f101',
    pause: '\f103',
    muted: '\f104',
    unmuted: '\f107',
    dot: '\f111',
    fullscreenOn: '\f108',
    fullscreenOff: '\f109',
);

$defaultIconSize: 24;

@mixin iconGlyph($icon, $before-after: 'after') {
    &::#{$before-after} {
        content: $icon;
    }
}

@mixin iconBlock($iconSize, $glyphSize: calc(#{$iconSize} * 5 / 6), $before-after: 'after') {
    &::#{$before-after} {
        width: rem-calc($iconSize);
        height: rem-calc($iconSize);
        font-size: rem-calc($glyphSize);
    }
}

@mixin iconStyle($before-after: 'after') {
    &::#{$before-after} {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@mixin icon($iconGlyph, $iconSize: $defaultIconSize, $glyphSize: calc(#{$iconSize} * 5 / 6), $before-after: 'after') {
    @include iconGlyph($iconGlyph, $before-after);
    @include iconBlock($iconSize, $glyphSize, $before-after);
    @include iconStyle($before-after);
}

// Map for creating AEM classes
$iconsMap: (
    'backward': $iconBackward,
    'forward': $iconForward,
    'pause': $iconPause,
    'play': $iconPlay,
    'profile': $iconProfile,
    'arrow-right': $iconArrowRight,
    'arrow-left': $iconArrowLeft,
    'arrow-down': $iconArrowDown,
    'exit': $iconExit,
    'heart': $iconHeart,
    'check': $iconCheck,
    'alert': $iconAlert,
    'cart': $iconCart,
    'location': $iconLocation,
    'globe': $iconGlobe,
    'menu': $iconMenu,
    'search': $iconSearch,
    'support': $iconSupport,
    'alert-alt': $iconAlertAlt,
    'caret-down': $iconCaretDown,
    'caret-up': $iconCaretUp,
    'caret-right': $iconCaretRight,
    'caret-left': $iconCaretLeft,
    'external-link': $iconExternalLink,
    'plus': $iconPlus,
    'share': $iconShare,
    'volume-mute': $iconVolumeMute,
    'closed-caption': $iconClosedCaption,
    'apple': $iconApple,
    'facebook': $iconFacebook,
    'linkedin': $iconLinkedin,
    'instagram': $iconInstagram,
    'youtube': $iconYoutube,
    'google': $iconGoogle,
    'we-chat': $iconWeChat,
    'vk': $iconVK,
    'tencent-weibo': $iconTencentWeibo,
    'sina-weibo': $iconSinaWeibo,
    'tumblr': $iconTumblr,
    'line': $iconLine,
    'renren': $iconRenren,
    'flickr': $iconFlickr,
    'accessibility': $iconAccessibility,
    'volume-unmute': $iconVolumeUnmute,
    'x-social': $iconXSocial,
    'tiktok': $iconTikTok,
    'apple-music': $iconAppleMusic,
    'spotify': $iconSpotify,
);

@each $name, $icon in $iconsMap {
    .cmp-button__icon--#{$name} {
        @include iconGlyph($icon);
    }
}

.cmp-button__icon {
    @include iconStyle;
    @include iconBlock($defaultIconSize);
}
