.container.three-up {
    width: 100%;

    .cmp-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: rem-calc(1100);
        gap: rem-calc(30);
        padding: rem-calc(15);
    }

    .teaser.content {
        flex: 1 1 calc(33.333% - #{rem-calc(30)});
        max-width: calc(33.333% - #{rem-calc(30)});
        margin-bottom: rem-calc(50);
    }

    @include breakpoint-down(tablet) {
        .cmp-container {
            gap: rem-calc(30);
        }

        .teaser.content {
            flex: 1 1 calc(50% - #{rem-calc(30)});
            max-width: calc(50% - #{rem-calc(30)});
            margin-bottom: rem-calc(50);
        }
    }
    @media (max-width: rem-calc(450)) {
        .cmp-container {
            display: block;
            padding: rem-calc(15);
        }

        .teaser.content {
            display: block;
            width: calc(100% - #{rem-calc(30)});
            max-width: calc(100% - #{rem-calc(30)});
            margin-bottom: rem-calc(60);
            margin-left: auto;
            margin-right: auto;
        }
    }
}
